.topbar {
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  background: var(--bg-gradient);
  color: var(--color-blanco);
  margin-bottom: -27px;
  padding-top: 0.6em;
  padding-left: 3.5em;
}

.topbar--section-name {
  display: none;
  padding-top: 0.5em;
  white-space: nowrap;
  flex-grow: 0;
}

.topbar--section-name h1 {
  font-size: 90%;
}

.topbar--searchArea {
  text-align: right;
  flex-grow: 9;
  margin-right: -4vw;
}

.topbar--iconArea {
  text-align: right;
  padding-right: var(--sp-mediano);
  flex-grow: 1;
}

.topbar--iconArea div {
  display: inline;
}

.topbar--iconArea .icon {
  color: var(--color-blanco);
}

.topbar--iconArea > div {
  margin-left: 10px;
}
.topbar--iconArea .menu-item .icon,
.topbar--dropdown .menu-item,
.topbar--iconArea .topbar--dropdown ul {
  color: var(--color-negro);
}

.topbar .topbar--avatar-menu {
  display: none;
}

/* Desktop */
@media screen and (min-width: 450px) {
  .topbar {
    height: 50px;
    margin-bottom: 0px;
    padding-left: var(--sp-mediano);
  }

  .topbar--section-name h1 {
    font-size: 1em;
  }

  .topbar .topbar--avatar-menu {
    display: inline-block;
  }

  .topbar--iconArea .topbar--dropdown {
    margin-left: var(--sp-chico);
  }

  .topbar--section-name {
    display: inline-block;
    flex-grow: 2;
  }

  .topbar--searchArea {
    margin-right: 0;
    flex-grow: 6;
  }

  .topbar--iconArea {
    flex-grow: 2;
  }
}
