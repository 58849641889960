.mbapp-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  max-width: 100vw;
  max-height: 100vh;
}

.mbapp-modal--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.mbapp-modal--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 90%;
}

.mbapp-modal--container--content {
  z-index: 1;
  padding: var(--sp-grande);
  padding-top: var(--sp-top);
  border-radius: var(--sp-chico);
  background: var(--color-blanco);
  max-width: 95vw;
  max-height: 80vh;
  transform: scale(0.9);
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.2s;
  box-shadow: var(--shadow-default);
  overflow-y: overlay;
}

.mbapp-modal .mbapp-modal--container--content--header {
  position: relative;
  border-bottom: 1px solid var(--color-gris-claro);
  margin-bottom: var(--sp-mediano);
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.mbapp-modal .mbapp-modal--container--content--header .btn-action {
  position: absolute;
  top: 1.2em;
  right: 0;
}

.mbapp-modal .mbapp-modal--container--content--footer {
  display: flex;
  flex-direction: row;
  margin-top: var(--sp-grande);
  padding-top: var(--sp-mediano);
  align-items: baseline;
  justify-content: flex-end;
}

.mbapp-modal .mbapp-modal--container--content--footer button:first-child {
  margin-right: var(--sp-chico);
}

.mbapp-modal .mbapp-modal--container--content--titulo {
  font-size: 1.3em;
  font-family: var(--font-gotham);
  max-width: 80%;
}

.mbapp-modal__show {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.mbapp-modal__show .mbapp-modal--container--content {
  transform: scale(1);
  transition-delay: 3s !important;
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (min-width: 450px) {
  .mbapp-modal--container--content {
    width: 700px;
  }
}
