.dropdown {
  position: relative;
  text-align: center;
  border-radius: 50%;
  padding: 0.4em 0.45em 0.2em 0.45em;
  margin-top: -4px;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown label {
  cursor: pointer;
}

.dropdown:focus,
.dropdown:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}

.dropdown .dropdown--menu {
  position: absolute;
  z-index: 99;
  background-color: var(--color-blanco);
  color: var(--color-negro);
  top: 50%;
  height: 0;
  padding: var(--sp-mediano);
  border-radius: 2px;
  opacity: 0;
  overflow: hidden;
  box-shadow: 5px 5px 17px 0px rgba(50, 50, 50, 0.3);
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1) !important;
  transition-delay: 0.1s;
  display: none !important;
}

.dropdown .dropdown--menu__show {
  opacity: 1;
  height: auto;
  cursor: initial;
  display: block !important;
}

.dropdown .dropdown--menu__show__top-right {
  left: 0%;
  top: -300%;
}
.dropdown .dropdown--menu__show__top-left {
  right: 0%;
  top: -300%;
}
.dropdown .dropdown--menu__show__bottom-right {
  left: 0%;
  top: 100%;
}
.dropdown .dropdown--menu__show__bottom-left {
  right: 0%;
  top: 100%;
}

.dropdown .dropdown--notification-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: "Roboto mono", Consolas, sans-serif;
  font-size: 0.7em;
  font-weight: bold;
  background-color: var(--color-rojo);
  border-radius: 50%;
  padding: 3px calc(5px);
}

.dropdown > img {
  display: block;
  border-radius: 50%;
}
