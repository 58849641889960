.reclamo-form {
  padding-bottom: 4em;
  transition: all 0.2s var(--std-easing);
}

.reclamo-form .reclamo-form--reclamo-container,
.reclamo-form .reclamo-form--asignacion-container {
  border-bottom: 1px solid var(--color-azul-secundario);
  padding-bottom: var(--sp-grande);
}

.reclamo-form h2 {
  font-size: 1.4em;
  margin-bottom: 1.8em;
  color: var(--color-negro);
}

.reclamo-form .subtitulo {
  margin-top: var(--sp-grande);
  margin-bottom: 1.8em;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--color-negro);
}

.reclamo-form textarea {
  width: 100%;
}

.reclamo-form textarea,
.reclamo-form .combobox,
.reclamo-form .inputGroup {
  margin-bottom: var(--sp-chico);
}

.reclamo-form .combobox > select {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid var(--color-gris-claro);
  background-color: var(--color-blanco);
  border-radius: var(--sp-chico);
}

.reclamo-form .inputGroup {
  position: relative;
  text-align: left;
}

.reclamo-form textarea,
.reclamo-form .inputGroup select,
.reclamo-form .inputGroup input {
  border: 2px solid var(--color-gris-claro);
  border-radius: var(--sp-chico);
  padding: 0.8rem;
  outline: none;
  width: 100%;
}

.reclamo-form hr {
  margin: var(--sp-mediano) 0;
  background: var(--sp-color-azul);
}
