.mbapp-message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sp-mediano);
}

.mbapp-message-item--avatar {
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  float: left;
  margin-right: var(--sp-mediano);
}

.mbapp-message-item--avatar img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.mbapp-message-item--body {
  padding: var(--sp-grande) var(--sp-mediano);
  border-radius: 10px;
  background-color: var(--color-blanco);
  box-shadow: var(--shadow-small);
}

.mbapp-message-item--nombre h4 {
  font-family: var(--font-sans-serif);
  padding-top: var(--sp-chico);
  margin-bottom: var(--sp-mediano);
  font-size: 1em;
}

.mbapp-message-item--nombre h4 span {
  color: var(--color-gris);
  font-size: 0.8em;
  font-weight: bold;
}

.mbapp-message-item--body p {
  font-size: 0.9em;
}
.mbapp-message-item--body .mbapp-pulsar {
  float: right;
  margin-right: var(--sp-chico);
}

.mbapp-message-item--footer {
  margin-top: var(--sp-mediano);
  text-align: right;
}
.mbapp-message-item--footer button:first-child {
  margin-right: var(--sp-chico);
}
