.app147-reclamo-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: calc(100vh - 50px);
  height: 100vh;
  color: var(--color-gris-claro);
}
.app147-reclamo-info h2 {
  margin-top: 1em;
  margin-bottom: var(--sp-chico);
  font-size: 1.2em;
}
