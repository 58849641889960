.app147--reclamo-item {
  position: relative;
  width: 100%;
  font-family: var(--font-sans-serif);
  padding: var(--sp-mediano);
  border: 1px solid #eee;
  border-radius: 8px;
  background: linear-gradient(145deg, #f0f0f0, #ffffff);
  box-shadow: 7px 7px 19px #d6d6d6, -7px -7px 19px #ffffff;
  margin-bottom: 1em;
  cursor: pointer;
  transition: transform 0.2s var(--std-easing);
}

.app147--reclamo-item--header h3,
.app147--reclamo-item--header h5 {
  font-family: var(--font-sans-serif);
}

.app147--reclamo-item--header h5 {
  font-size: 1.1em;
}

.app147--reclamo-item--header h3 {
  font-size: 1.4em;
  margin-bottom: var(--sp-mediano);
}
.app147--reclamo-item--header--area {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9em;
}
.app147--reclamo-item--header--tipo {
  font-size: 0.9em;
}

.app147--reclamo-item--footer {
  margin-top: 1em;
}
.app147--reclamo-item--footer--duenio {
  color: var(--color-azul-secundario);
  font-weight: bold;
}
.app147--reclamo-item--footer--fecha {
  font-size: 0.8em;
  font-weight: bold;
  color: var(--color-azul-secundario);
  margin-top: var(--sp-mediano);
}

.app147--reclamo-item--footer--comentarios {
  display: flex;
  margin-top: 1em;
  font-weight: bold;
  color: var(--color-negro);
}
.app147--reclamo-item--footer--comentarios .icon {
  margin-right: 0.3em;
  margin-top: 2px;
}
.app147--reclamo-item--prioridad {
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  padding: 4px 8px 2px 8px;
  border-radius: var(--sp-mediano);
  top: var(--sp-mediano);
  right: var(--sp-mediano);
}

.app147--reclamo-item--prioridad span {
  margin-left: var(--sp-chico);
  font-size: 0.7em;
  font-weight: bold;
  padding-top: 0.3em;
  text-transform: uppercase;
}

.app147--reclamo-item--prioridad__alta {
  background-color: red;
  color: white;
}

.app147--reclamo-item--prioridad__media {
  background-color: orange;
  color: white;
}
.app147--reclamo-item--prioridad__baja {
  background-color: gray;
  color: white;
}

.app-147--reclamo-item--asignacion {
  display: flex;
  flex-direction: column;
}

.app147--panel--reclamos--container .mbapp-pulsar {
  float: right;
  top: 2px;
}

@media screen and (min-width: 450px) {
  .app147--reclamo-item:hover {
    transform: scale(1.005);
    transition: transform 0.25s;

    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .app147--reclamo-item--header h5 span,
  .app147--reclamo-item--header h5 {
    font-size: 0.9em;
    margin-bottom: 1em;
  }
  .app147--reclamo-item--header h3 {
    font-size: 1.3em;
  }
  .app147--reclamo-item--header--area,
  .app147--reclamo-item--header--tipo {
    font-size: 0.8em;
  }

  .app147--reclamo-item--footer--comentarios,
  .app147--reclamo-item--footer--duenio {
    font-size: 0.8em;
  }

  .app147--reclamo-item--footer--comentarios .icon {
    margin-right: 0.3em;
    margin-top: 0px;
  }

  .app147--reclamo-item--prioridad span {
    margin-top: -2px;
  }

  .app-147--reclamo-item--asignacion {
    flex-direction: row;
  }

  .app-147--reclamo-item--asignacion .app147--reclamo-item--header--area {
    margin-right: var(--sp-mediano);
    padding-right: var(--sp-mediano);
    border-right: 4px solid var(--color-verde);
  }
}
