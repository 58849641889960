.app147--tipo_reclamo--item,
.app147--tipo_reclamo--item--action-container {
  display: flex;
  flex-direction: row;
}

.app147--tipo_reclamo--item,
.app147--tipo_reclamo--item > input {
  width: 100%;
}
.app147--tipo_reclamo--item > input {
  border: none;
  padding: var(--sp-mediano);
}
.app147--tipo_reclamo--item button {
  border: none;
  padding: 0 var(--sp-mediano);
  cursor: pointer;
}
.app147--tipo_reclamo--item div:last-child button {
  background: var(--color-rojo-claro);
  border-radius: 0 var(--sp-chico) var(--sp-chico) 0;
  color: var(--color-blanco);
}

.app147--tipo_reclamo--item div:first-child button {
  border-radius: var(--sp-chico) 0 0 var(--sp-chico);
}

.app147--tipo_reclamo--item div:last-child button:hover {
  background: var(--color-rojo);
}

.app147--tipo_reclamo--item {
  margin-bottom: var(--sp-mediano);
  border: 1px solid var(--color-gris-mas-claro);
  border-radius: var(--sp-chico);
}

.app147--tipo_reclamo--item div:last-child .app147--tipo_reclamo--btn-nuevo {
  background: var(--bg-gradient);
}
.app147--tipo_reclamo--item
  div:last-child
  .app147--tipo_reclamo--btn-nuevo:hover {
  background: var(--bg-gradient-darker);
}
