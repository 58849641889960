.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: var(--std-easing);
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: 0.25em;
  z-index: 100;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: white;
}

.hamburger-box {
  width: 30px;
  height: 14px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

.sidebar {
  position: relative;
  width: 240px;
  max-width: 220px;
  height: 100vh;
  max-height: calc(100vh);
  transition: all 0.2s var(--std-easing) !important;
}

.sidebar--inner--title {
  display: block;
  padding-left: var(--sp-mediano);
  margin-bottom: var(--sp-grande);
  width: 100%;
  font-size: 1.8em;
  line-height: 1;
  color: var(--color-verde);
  transition: font-size 0.2s var(--std-easing);
}

.sidebar__collapsed {
  width: 65px;
  transition: all 0.2s var(--std-easing);
}

.sidebar__collapsed .sidebar--inner .sidebar--inner--title {
  font-size: 1em;
  transition: font-size 0.2s var(--std-easing);
}

.sidebar .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.sidebar .sidebar--inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 220px;
  height: 100%;
  background: var(--color-azul-secundario);
  color: var(--color-blanco);
  padding-top: 5em;
}

.sidebar .sidebar--inner .sidebar--inner-title--menu {
  width: 100%;
}

.sidebar .sidebar--inner .sidebar--inner-title--menu li {
  list-style: none;
}

.sidebar--mobile {
  position: absolute;
  z-index: 99;
  left: -100%;
  transition: all 0.2s var(--std-easing) !important;
}

.sidebar--mobile .overlay {
  width: 0;
  opacity: 0;
  transition: opacity 0.25s var(--std-easing) !important;
}

.sidebar--mobile .sidebar--inner {
  box-shadow: 11px 0 25px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 11px 0 25px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 11px 0 25px rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
  transition: opacity 0.2s var(--std-easing);
}

.sidebar--mobile__show {
  width: 100vw;
  height: 100vh;
  left: 0;
  transition: opacity 0.2s var(--std-easing) !important;
}

.sidebar--mobile__show .overlay {
  width: 100vw;
  opacity: 1;
  transition: opacity 0.2s var(--std-easing) !important;
}

.sidebar--mobile__show .sidebar--inner {
  width: 100vw;
  transform: translateX(0);
  transition: all 0.2s var(--std-easing);
  transition-delay: 0.1s;
}

.sidebar--user-menu {
  position: absolute;
  bottom: 4.2em;
  width: 100%;
  transition: all 0.2s var(--std-easing);
}

.sidebar--logo {
  position: absolute;
  bottom: var(--sp-mediano);
  left: var(--sp-mediano);
  width: 40%;
}

.sidebar .sidebar--inner .sidebar--inner--brand img {
  width: 100%;
  display: inline;
  padding: var(--sp-mediano);
  max-width: 6em;
  margin-top: -1em;
  margin-bottom: var(--sp-grande);
}

@media screen and (min-width: 450px) {
  .sidebar {
    max-height: 100vh;
  }
  .sidebar .sidebar--inner .sidebar--user-menu {
    display: none;
  }
  .sidebar--logo {
    left: var(--sp-mediano);
  }

  .sidebar .sidebar--inner .sidebar--inner--brand img {
    max-width: 6em;
  }
}
