.mbapp-organigrama {
  position: relative;
}
.mbapp-organigrama .mbapp-organigrama--launcher {
  text-align: left;
  width: 100%;
  padding: var(--sp-mediano);
  border-radius: var(--sp-chico);
  border: 2px solid var(--color-gris-claro);
  background: var(--color-blanco);
}
.mbapp-organigrama .mbapp-organigrama--launcher label {
  font-family: var(--font-gotham);
  font-size: 0.9rem;
  position: absolute;
  background: var(--color-blanco);
  padding: calc(var(--sp-chico) / 2) calc(var(--sp-chico) * 2)
    calc(var(--sp-chico) / 2) var(--sp-chico);
  top: -0.7em;
  left: var(--sp-mediano);
  border-radius: 3px;
  z-index: 3;
}

.mbapp-organigrama .mbapp-organigrama--launcher:focus {
  border-color: var(--color-azul);
}
.mbapp-organigrama .mbapp-organigrama--launcher:focus label {
  color: var(--color-azul-secundario);
}

.mbapp-organigrama .mbapp-organigrama--selector {
  visibility: collapse;
  top: -75%;
  left: 0%;
  position: absolute;
  z-index: 4;
  border-radius: var(--sp-chico);
  background: var(--color-blanco);
  width: 100%;
  box-shadow: var(--shadow-small);
  opacity: 0;
  transform: scale(0.8);
}

.mbapp-organigrama .mbapp-organigrama--selector__show {
  visibility: visible;
  opacity: 1;
  border: 1px solid var(--color-gris-claro);
  padding: var(--sp-grande) var(--sp-mediano);
  box-shadow: var(--shadow-small);
  height: auto;
  transform: scale(1);
  transition: all 0.25s var(--std-easing);
}

.mbapp-organigrama .mbapp-organigrama--selector--footer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  justify-content: space-around;
}
.mbapp-organigrama .mbapp-organigrama--selector--footer button {
  display: inline;
  text-align: center;
  width: 48%;
}
.mbapp-organigrama .mbapp-organigrama--selector > select:last-child {
  margin-bottom: 0;
}
