.toolbar147 {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #ddd;
  padding: 1em var(--sp-mediano) 0.5em var(--sp-mediano);
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  max-width: 100vw;
  background-color: var(--color-blanco);
  color: var(--color-negro);
  box-shadow: -0px 18px 15px #fff;
  height: 3.7em;
}

.toolbar147 label:first-child {
  display: block;
  width: 100%;
  padding-top: 0.25em;
  text-align: left;
}

.toolbar147 div {
  flex-grow: 2;
}

.toolbar147 div:first-child {
  flex-grow: 8;
}

.toolbar147 label,
.toolbar147 .dropdown label {
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.toolbar147--dropdown .dropdown {
  color: var(--color-negro);
}
.toolbar147--dropdown .dropdown:after {
  content: " ⌄";
  position: relative;
  top: -2px;
}
.toolbar147 .toolbar147--nuevo {
  display: none;
}

.toolbar147 .btn-action {
  margin-top: -0.4em;
  margin-right: var(--sp-mediano);
}

.toolbar147 label {
  padding-top: 0.3em !important;
}

@media screen and (min-width: 450px) {
  .toolbar147 {
    padding-left: var(--sp-mediano);
    padding-right: 0;
  }

  .toolbar147 .toolbar147--nuevo {
    display: block;
    margin-top: -7px;
    padding-bottom: 5px;
  }

  .toolbar147--nuevo .toolbar147--button--icon {
    position: relative;
    display: inline;
    margin-right: var(--sp-chico);
    bottom: -3px;
  }
}
