.btn {
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 2px solid #eee;
}

.btn-default {
  font-family: var(--font-gotham);
  font-size: 0.8rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: inline-flex;
  flex-direction: row;
  padding: var(--sp-chico) var(--sp-mediano);
  padding-top: 0.7em;
  border-radius: var(--sp-chico);
  text-align: center;
}

.btn-default:hover {
  border-color: var(--color-gris);
  background: var(--color-gris);
  color: var(--color-blanco);
}

.btn-pill {
  border-radius: calc(var(--sp-mediano) * 2);
  padding: var(--sp-mediano);
  background: var(--bg-gradient);
  color: var(--color-blanco);
  border-radius: 3em;
  padding: var(--sp-chico) var(--sp-mediano);
  background: var(--bg-gradient);
  color: var(--color-blanco);
}

.btn-small {
  font-size: 0.8em;
  padding: 0.35em 1em 0.2em 1em;
  border-radius: var(--sp-chico);
}

.btn-big {
  width: 100%;
  font-size: 1.1rem;
  margin-top: var(--sp-grande);
  padding: var(--sp-mediano);
}

.btn-primary {
  background: var(--color-azul);
  color: var(--color-blanco);
  border-color: var(--color-azul);
}

.btn-primary:hover {
  border-color: var(--color-azul-secundario);
}

.btn-ghost {
  box-sizing: content-box;
  background: none;
  border: 2px solid var(--color-gris);
}

.btn-ghost-primary {
  border: 2px solid var(--color-azul);
  color: var(--color-azul);
}

.btn-ghost-primary:hover {
  border-color: var(--color-azul);
  background: var(--color-azul);
}

.btn-primary:hover {
  background: var(--bg-gradient);
  background-color: var(--color-azul);
}

.btn-primary .icon {
  position: relative;
  top: -3px;
  margin-right: var(--sp-chico);
}

.btn-action {
  border: none;
  padding: 0.4em 0.5em;
  border-radius: 50%;
  background: none;
}

.btn-action:hover {
  background: var(--color-gris-mas-claro);
}

.btn-action .icon {
  position: relative;
  top: 2px;
}

.btn-warning {
  background: var(--color-rojo-claro);
  border-color: var(--color-rojo-claro);
}
.btn-warning:hover {
  background: var(--color-rojo);
  border-color: var(--color-rojo);
}

.btn[disabled] {
  background: var(--color-gris-claro);
  border-color: var(--color-gris-claro);
  cursor: not-allowed;
}

.btn[disabled]:hover {
  background: var(--color-gris-claro);
  border-color: var(--color-gris-claro);
}

.btn-loadmore {
  display: inline-block;
  width: 100%;
  border: none;
  padding: var(--sp-mediano) 0;
  color: var(--color-azul);
  background: var(--color-gris-mas-claro);
  margin-bottom: -25px;
  margin-left: -15px;
  margin-right: -25px;
  width: calc(100% + 35px);
  margin-top: var(--sp-grande);
}
