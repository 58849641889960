.app147--panel--tipo_reclamo--listado {
  padding: var(--sp-mediano);
  padding-top: var(--sp-grande);
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 450px) {
  .app147--panel--tipo_reclamo--listado {
    height: calc(100vh - 108px);
    overflow-y: auto;
    flex-direction: column;
  }
}
