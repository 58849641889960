.app147--reportes {
  overflow-x: hidden;
  max-height: calc(100vh - 109px);
  display: grid;
  /*grid-template-areas: "widget12 widget12 widget12" "widget1 widget2 widget3";*/
  grid-template-areas:
    "widget12 widget12 widget12" "widget1 widget1 widget1" "widget2 widget2 widget2"
    "widget3 widget3 widget3";
  padding: var(--sp-grande) var(--sp-mediano);
  max-width: 100%;
}

.app147--panel--reportes--container .app147--widget {
  height: 45vh;
  border: 1px solid var(--color-gris-claro);
  padding: var(--sp-mediano);
  border-radius: var(--sp-chico);
  margin-bottom: var(--sp-mediano);
  max-width: 100%;
}

.app147--panel--reportes--container .app147--widget-12 {
  grid-area: widget12;
}

.app147--panel--reportes--container .app147--widget-l {
  grid-area: widget1;
}

.app147--panel--reportes--container .app147--widget-m {
  grid-area: widget2;
}

.app147--panel--reportes--container .app147--widget-r {
  grid-area: widget3;
}

@media screen and (min-width: 450px) {
  .app147--reportes {
    grid-template-areas: "widget12 widget12 widget12" "widget1 widget2 widget3";
  }
  .app147--panel--reportes--container .app147--widget-l {
    margin-right: var(--sp-mediano);
  }
  .app147--panel--reportes--container .app147--widget-r {
    margin-left: var(--sp-mediano);
  }
}
