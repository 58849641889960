.app147--panel--detalle-reclamo--header,
.app147--panel--detalle-reclamo--respuestas-container,
.app147--panel--detalle-reclamo--responder-container {
  border-bottom: 1px solid var(--color-gris-claro);
  padding: 0 0 var(--sp-grande) 0;
  margin-bottom: var(--sp-grande);
}

.app147--panel--detalle-reclamo--header {
  margin-top: var(--sp-chico);
}
.app147--panel--detalle-reclamo--header * {
  font-family: var(--font-sans-serif);
}

.app147--panel--detalle-reclamo--header h5 span {
  font-size: 0.9em;
  color: var(--color-gris-claro);
  font-weight: bold;
}

.app147--panel--detalle-reclamo--header h4,
.app147--panel--detalle-reclamo--header h3 {
  font-family: var(--font-gotham);
  margin-bottom: var(--sp-chico);
  font-size: 1.1em;
  color: var(--color-azul-secundario);
}

.app147--panel--detalle-reclamo--header ul,
.app147--panel--detalle-reclamo--header p {
  margin-bottom: var(--sp-grande);
}

.app147--panel--detalle-reclamo--header ul li {
  margin-bottom: var(--sp-chico);
}
.app147--panel--detalle-reclamo--header h1 {
  font-size: 1.5em;
  margin-bottom: var(--sp-mediano);
}

.app147--panel--detalle-reclamo--header--solicitante h2 {
  font-size: 1.1em;
  text-transform: capitalize;
}

.app147--panel--detalle-reclamo--respuestas-container {
  padding-bottom: 3em;
  border: none;
}

.app147--panel--detalle-reclamo--respuestas-container > h2 {
  font-size: 1.8em;
  margin-bottom: var(--sp-grande);
  color: var(--color-azul-secundario);
}

.app147--panel--detalle-reclamo--respuestas-responder {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.app147--panel--detalle-reclamo--input-container {
  display: flex;
  border-radius: 10px 10px 10px 10px;
  background-color: var(--color-blanco);
  box-shadow: var(--shadow-small);
  border: none;
  padding: 0;
  resize: none;
  width: 100% !important;
  max-height: 6em;
}

.app147--panel--detalle-reclamo--respuestas-responder textarea {
  display: inline-block;
  margin: 0.7em var(--sp-chico) var(--sp-chico) var(--sp-mediano);
  border: none;
  resize: none;
  width: 100% !important;
  height: auto;
}

.app147--panel--detalle-reclamo--respuestas-responder .area-expanded {
  align-items: flex-end;
  justify-content: baseline;
  height: auto;
}

.app147--panel--detalle-reclamo--respuestas-responder .btn-send {
  padding: 1em 1.2em;
  background: var(--bg-gradient);
  color: var(--color-blanco);
  max-height: 4.2em;
  margin-left: var(--sp-chico);
  box-shadow: var(--shadow-small);
}

.app147--panel--detalle-reclamo--respuestas-responder .btn-send .icon {
  left: -1px;
  top: 3px;
}

.app147-reclamo--more-settings {
  float: right;
  margin-right: var(--sp-chico);
}

.app147-reclamo--more-settings .dropdown {
  top: 2px;
}

.app147-reclamo--more-settings .dropdown:hover {
  background: #eee !important;
}

.app147--panel--detalle-reclamo--respuestas-responder .toggle-button button {
  box-shadow: var(--shadow-small);
}
.app147--panel--detalle-reclamo--respuestas-responder
  .toggle-button__show
  label {
  left: 4.5em;
}
