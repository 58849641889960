.mbapp-pulsar {
  display: block;
  position: relative;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.mbapp-pulsar__verde {
  background-color: green;
  animation: pulse-green 2s infinite;
}

.mbapp-pulsar__naranja {
  background-color: orange;
  animation: pulse-orange 2s infinite;
}

.mbapp-pulsar__rojo {
  background-color: red;
  animation: pulse-red 2s infinite;
}

@keyframes pulse-green {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(0, 169, 44, 0);
    box-shadow: 0 0 0 20px rgba(0, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(0, 169, 44, 0);
  }
}

@keyframes pulse-orange {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 165, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 165, 0, 0);
    box-shadow: 0 0 0 20px rgba(255, 165, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
  }
}

@keyframes pulse-red {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
