.app147--persona {
  font-family: var(--font-sans-serif);
  padding-top: var(--sp-mediano);
  margin-bottom: var(--sp-grande);
  padding-bottom: var(--sp-grande);
  border-bottom: 1px solid var(--color-azul);
  transition: all cubic-bezier(0.19, 1, 0.22, 1);
}

.app147--persona h2 {
  font-size: 1.4em;
}
.app147--persona--search {
  margin-top: var(--sp-mediano);
  margin-bottom: var(--sp-mediano);
  transition: all cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-direction: row;
}

.app147--persona--search--input {
  display: block;
  width: 100%;
  border: none;
  border-right: none;
  border: 2px solid var(--color-gris);
  padding-left: var(--sp-mediano);
  padding-right: var(--sp-mediano);
}

.app147--persona--search--input:focus,
.app147--persona--search--button:hover {
  border-color: var(--color-negro);
}

.app147--persona--search--button:hover {
  background-color: var(--color-negro);
}

.app147--persona--search--button {
  position: initial;
  border: 2px solid var(--color-gris);
  border-left: none;
  color: var(--color-blanco);
  background: var(--color-gris);
  border-radius: 0 3px 3px 0;
}

.app147--persona h3 {
  font-size: 1.2em;
  font-family: var(--font-sans-serif);
}

.app147--persona--datos h4 {
  margin-top: var(--sp-grande);
  font-size: 1.2em;
  color: var(--color-azul-secundario);
}

.app147--persona--datos--domicilio {
  font-weight: bold;
  margin-bottom: var(--sp-mediano);
}

.app147--persona--datos--domicilio span {
  font-size: 0.8em;
}

.app147--persona--datos--contacto a {
  position: relative;
  display: block;
  text-decoration: none;
  color: var(--color-negro);
  font-weight: bold;
  background: var(--color-verde);
  padding: var(--sp-mediano) var(--sp-grande);
  text-align: center;
  border-radius: 4px;
  margin-bottom: var(--sp-mediano);
}

.app147--persona--datos--contacto a .icon {
  margin-bottom: var(--sp-chico);
}

.app147--persona--datos--contacto--faltantes {
  margin-top: var(--sp-grande);
  text-align: center;
}
.app147--persona--datos--contacto--faltantes p {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: var(--sp-chico);
}

@media screen and (min-width: 450px) {
  .app147--persona--datos--contacto {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .app147--persona--datos--contacto a {
    display: block;
    flex-shrink: 0;
    flex-grow: 1;
    width: 45%;
    margin: 5px;
  }

  .app147--persona--datos--contacto a:hover {
    background-color: var(--color-azul);
    color: var(--color-blanco);
  }
}
