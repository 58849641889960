.sidebar-item {
  position: relative;
  color: var(--color-blanco);
  display: block;
  width: 100%;
  padding: var(--sp-mediano);
  padding-left: 1.2em;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.sidebar-item:hover {
  cursor: pointer;
  background-color: var(--color-azul);
}

.sidebar-item .sidebar-item--selected {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--color-blanco);
  transition: width 0.2s var(--std-easing);
}

.sidebar-item__selected .sidebar-item .sidebar-item--selected {
  width: 4px;
}

.sidebar-item .icon {
  position: relative;
  float: left;
  top: -3px;
  margin-right: 0.5em;
}

.sidebar-item .sidebar-item__text {
  opacity: 1;
  display: block;
  position: absolute;
  top: 1.1em;
  left: calc(var(--sp-mediano) * 3.5);
  font-family: "Gotham Rounded Book", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  transition: opacity 0.2s var(--std-easing);
  width: 220px !important;
}

.sidebar-item .sidebar-item__no-text {
  opacity: 0;
  font-size: 0;
  transition: all 0.2s var(--std-easing);
}

.sidebar-item--avatar {
  position: absolute;
  border-radius: 50%;
  top: 7px;
  left: 11px;
  width: 32px;
  height: 32px;
  border: 2px solid var(--color-verde);
}
