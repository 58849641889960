.landing-147 {
  min-height: 100vh;
  font-size: 16px;
  background: var(--bg-gradient);
  color: white;
}
.landing-147--container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--sp-mediano);
  width: 100%;
  height: 100vh;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.landing-147--container h1 {
  font-family: var(--font-gotham);
  font-size: 1.6em;
  color: var(--color-blanco);
  margin-bottom: var(--sp-mediano);
}
.landing-147--container .btn-pill {
  width: 100%;
  display: inline-block;
  max-width: 18em;
}

.landing-147--brand {
  position: absolute;
  top: var(--sp-mediano);
  left: var(--sp-grande);
  max-width: 6em;
  z-index: 2;
}
.landing-147--hero {
  width: 100%;
  height: auto;
  margin-bottom: -0.5em;
}

.landing-147--bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.landing-147--bg_top-right,
.landing-147--bg_bottom-right {
  position: absolute;
  width: 50vw;
  animation-duration: 15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.landing-147--bg_top-right {
  right: 0;
  top: 0;
  animation-name: toLeft;
}
.landing-147--bg_bottom-right {
  bottom: 0;
  left: 0;
  animation-name: toRight;
}
.landing-147--bg small {
  font-family: monospace;
  position: absolute;
  bottom: var(--sp-mediano);
  right: var(--sp-grande);
}

@media screen and (min-width: 450px) {
  .landing-147--container {
    flex-direction: row;
    max-width: 70em;
    padding-top: 10vh;
  }

  .landing-147--container div:last-child {
    max-width: 25em;
  }
  .landing-147--container h1 {
    font-size: 2em;
  }
  .landing-147--brand {
    max-width: 6.5em;
  }
}

@keyframes toLeft {
  from {
    right: -10vw;
  }
  to {
    right: 0;
  }
}
@keyframes toRight {
  from {
    left: -10vw;
  }
  to {
    left: 0;
  }
}
