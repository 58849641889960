.mbappLogin {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 100%;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100vw;
  padding-left: var(--sp-mediano);
  padding-right: var(--sp-mediano);
  background: var(--color-blanco);
  border-radius: var(--sp-grande) var(--sp-grande) 0 0;
}
.login {
  text-align: center;
}
.login .inputGroup {
  position: relative;
  margin-bottom: var(--sp-mediano);
  text-align: left;
}
.login .inputGroup input {
  border: 2px solid var(--color-gris);
  border-radius: var(--sp-chico);
  padding: 0.8rem;
  outline: none;
  width: 100%;
}

.login .inputGroup input:focus {
  border-color: var(--color-azul);
}

header {
  padding-top: var(--sp-grande);
  text-align: center;
}

.logo {
  max-width: 60px;
  margin-bottom: 1.5em;
}

.titulo {
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  margin-bottom: var(--sp-chico);
}
.subtitulo {
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
  color: var(--color-gris-secundario);
  margin-top: 0;
}

.text-center {
  text-align: center;
}

.text-alert {
  color: var(--color-rojo);
}

small {
  font-size: 0.7rem;
}

.blk {
  display: block;
}

.mbappLogin a {
  margin-top: 1rem;
  color: var(--color-azul);
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: var(--color-gris-secundario);
  text-decoration: none;
  font-size: 0.9rem;
}

.mbappLogin .hidden {
  display: none;
}

.mbappLogin .formulario {
  transition: all 0.2s ease-out;
}

@media screen and (min-width: 450px) {
  .mbappLogin {
    background: var(--color-blanco);
    transition: all 0.2s ease-out;
  }
  .mbappLogin .container {
    max-width: 450px;
  }
  .mbappLogin .formulario {
    border: 1px solid var(--color-azul-claro);
    border-radius: var(--sp-mediano);
    padding: calc(var(--sp-grande)) var(--sp-grande);
    margin-top: var(--sp-grande);
  }
}
