.menu-item {
  display: flex;
  padding: 10px 15px;
  padding-bottom: 5px;
  cursor: pointer;
  margin-left: -1em;
  margin-right: -1em;
}

.menu-item span {
  font-family: var(--font-gotham);
  font-weight: 400;
  font-size: 0.9em;
  float: left;
}
.menu-item:hover {
  background-color: var(--color-gris-mas-claro);
}

.menu-item .icon {
  position: relative;
  margin-right: 1em;
  top: -2px;
}

.menu-item .menu-item--separator {
  width: 100%;
  border-top: 1px solid var(--color-gris-claro);
  height: 1px;
  padding: 0;
}
