.mbapp-app-drawer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 50;
  position: relative;
}

.mbapp-app-drawer--app-item {
  flex: 0 0 33.33%;
  text-align: center;
  width: 100%;
  max-width: 5.8em;
}
.mbapp-app-drawer--app-item a {
  padding: var(--sp-mediano);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: var(--sp-chico);
}
.mbapp-app-drawer--app-item a:hover {
  background: var(--color-gris-mas-claro);
}
.mbapp-app-drawer--app-item img {
  display: flex;
  flex-direction: column;
  max-width: 46px;
  display: inline-block;
  margin-bottom: var(--sp-mediano);
  border-radius: 50%;
  overflow: hidden;
}
.mbapp-app-drawer--app-item p {
  display: block;
  font-size: 0.9em;
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
