.toggle-button {
  position: relative;
}

.toggle-button .btn-unlock,
.toggle-button .btn-lock {
  padding: 1em 1.2em;
  background: var(--bg-gradient);
  color: var(--color-blanco);
  max-height: 4.2em;
  margin-left: var(--sp-chico);
  box-shadow: var(--shadow-small);
}

.toggle-button .btn-lock,
.toggle-button .btn-unlock {
  position: relative;
  background: #eee;
  box-shadow: none;
  color: var(--color-gris);
  margin: 0;
  margin-right: var(--sp-chico);
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s var(--std-easing);
  z-index: 1;
}

.toggle-button .btn-lock {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: #009900;
  color: var(--color-blanco);
  transform: scale(0.7);
  opacity: 0;
}

.toggle-button__show .btn-unlock {
  z-index: 1;
  transform: scale(0.8);
  transition: all 0.25s var(--std-easing);
}

.toggle-button__show .btn-lock {
  z-index: 2;
  opacity: 1;
  transform: scale(1);
  transition: all 0.25s var(--std-easing) !important;
}

.toggle-button label {
  opacity: 0;
  position: absolute;
  bottom: -20px;
  left: 10%;
  width: 250px;
  font-size: 0.8em;
  color: #009900;
  font-weight: bold;
  transition-delay: 0.2s !important;
  transition: all 0.2s var(--std-easing);
}

.toggle-button__show label {
  opacity: 1;
  left: 20%;
  transition-delay: 0.2s !important;
  transition: all 0.25s var(--std-easing);
}
